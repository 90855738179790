import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { toast } from "react-hot-toast";
import SectionHeadingStyle3 from "../../../SectionHeading/SectionHeadingStyle3";
import Spacing from "../../../Spacing";
import LoaderComponent from "../../../Loader/Loader";
import CreateOrder from "../CreateOrder";

const CreateOrderPage = ({ darkMode }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Get page from URL or default to 1
  const queryParams = new URLSearchParams(location.search);
  const pageFromUrl = parseInt(queryParams.get("page")) || 1;

  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(pageFromUrl);
  const [totalPages, setTotalPages] = useState(1);

  // Update URL when page changes
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    navigate(`/transactions?page=${newPage}&limit=10`);
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("userToken");

        if (!token) {
          toast.error("You need to be logged in to view transactions");
          return;
        }

        const response = await fetch(
          `https://getusertransactions-qj6yfdqbiq-uc.a.run.app?page=${currentPage}&limit=10`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setTransactions(data.transactions);
        setTotalPages(data.pagination.totalPages);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [currentPage]);

  const formatDate = (timestamp) => {
    return new Date(timestamp._seconds * 1000).toLocaleDateString();
  };

  return (
    <>
      <Spacing lg="100" md="80" />
      <SectionHeadingStyle3
        title="Create Orders"
        subTitle=""
        variant="text-left"
        href="/transactions"
      />
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="table-responsive"></div>
            <CreateOrder />
            <div className="cs_height_30 cs_height_lg_30" />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateOrderPage;

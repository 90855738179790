import React from "react";
import Spacing from "../Spacing";
import HeroStyle2 from "../Hero/HeroStyle2";
import Brands from "../Brands";
import Marquee from "../Marquee";
import Cta from "../Cta";
import SectionHeading from "../SectionHeading";
import Accordion from "../Accordion";
import PostCarousel from "../Slider/PostCarousel";
import TestimonialSlider from "../Slider/TestimonialSlider";
import Award from "../Award";
import VideoModal from "../VideoModal";
import ServiceSlider from "../Slider/ServiceSlider";
import AboutStyle2 from "../About/AboutStyle2";
import FunFact from "../FunFact";
import Button from "../Button";
import CaseStudy from "../CaseStudy";
import { pageTitle } from "../../helpers/PageTitle";
import { useNavigate } from "react-router-dom";
const servideData = [
  {
    iconSrc: "/section 2/img1.png",
    title: "Startups",
    subTitle:
      "Um momento fornecido. Interessante e você vê tudo e não escuta. Imagem confiante ela uma o que eu nem menos.",
    btnText: "Investir",
    btnUrl: "/Invest",
  },
  {
    iconSrc: "/section 2/img2.png",
    title: "Energia",
    subTitle:
      "Um momento fornecido. Interessante e você vê tudo e não escuta. Imagem confiante ela uma o que eu nem menos.",
    btnText: "Investir",
    btnUrl: "/Invest",
  },
  {
    iconSrc: "/section 2/img3.png",
    title: "Real State",
    subTitle:
      "Um momento fornecido. Interessante e você vê tudo e não escuta. Imagem confiante ela uma o que eu nem menos.",
    btnText: "Investir",
    btnUrl: "/Invest",
  },
  {
    iconSrc: "/section 2/img4.png",
    title: "Big Data Inc.",
    subTitle:
      "Um momento fornecido. Interessante e você vê tudo e não escuta. Imagem confiante ela uma o que eu nem menos.",
    btnText: "Investir",
    btnUrl: "/Invest",
  },
];
const funfactData = [
  { title: "Investido", number: "372M" },
  { title: "Projetos Lançados", number: "65" },
  { title: "Investidores", number: "1k" },
  { title: "Retorno Anual", number: "+24%" },
];
const awardData = [
  {
    brand: "1",
    title: "Design UI/UX do mês",
    subTitle:
      "Investimentos através da plataforma trazem alguns dos retornos mais rápidos do segmento. Na Barzel, já foram 3: Alter, Wuzu e Finansystech.",
    date: "12 de Dezembro de 2023",
    awardImgUrl: "/images/creative-agency/award_img_1.svg",
  },
  {
    brand: "2",
    title: "Prêmio de design CSS",
    subTitle:
      "Apoie o empreendedorismo, gere empregos e impulsione a economia.",
    date: "05 de Janeiro de 2022",
    awardImgUrl: "/images/creative-agency/award_img_2.svg",
  },
  {
    brand: "3",
    title: "Site do dia",
    subTitle:
      "Troque experiências: ensine e aprenda com empreendedores e nossa comunidade de investidores.",
    date: "20 de Março de 2021",
    awardImgUrl: "/images/creative-agency/award_img_3.svg",
  },
];
const testimonialData = [
  {
    text: "Estou impressionado com os retornos da Barzel - meus investimentos têm rendido consistentemente 20-25% ao ano. A plataforma facilita o acompanhamento do desempenho e o reinvestimento dos lucros. A melhor decisão de investimento que já tomei!",
    avatarName: "Daniel Goldfarb",
    avatarDesignation: "Cirurgião",
  },
  {
    text: "A Barzel é fantástica! Plataforma simples e transparente com ótimo suporte. Consegui financiar meu projeto facilmente. Recomendo muito!",
    avatarName: "Gustavo Albuquerque",
    avatarDesignation: "CEO da Bosforo",
  },
  {
    text: "A Barzel supera as expectativas! Sua plataforma direta e transparente e suporte excepcional tornaram o financiamento do meu projeto muito fácil. Não posso recomendar o suficiente!",
    avatarName: "Avraham Goldstein",
    avatarDesignation: "CEO da Delta",
  },
];
const caseStudyData = [
  {
    thumbnailSrc: "/images/marketing-agency/case_study_1.jpeg",
    title: "Gestão de marketing digital",
    category: "Marketing",
    number: "01",
    href: "/case-study-details",
  },
  {
    thumbnailSrc: "/images/marketing-agency/case_study_2.jpeg",
    title: "Gestão de marketing digital",
    category: "Marketing",
    number: "02",
    href: "/case-study-details",
  },
  {
    thumbnailSrc: "/images/marketing-agency/case_study_3.jpeg",
    title: "Gestão de marketing digital",
    category: "Marketing",
    number: "03",
    href: "/case-study-details",
  },
  {
    thumbnailSrc: "/images/marketing-agency/case_study_4.jpeg",
    title: "Gestão de marketing digital",
    category: "Marketing",
    number: "04",
    href: "/case-study-details",
  },
];
const postData = [
  {
    thumbnailSrc: "/section 7/img1.png",
    title: "Como evitar que o medo arruíne seu negócio artístico com confiança",
    date: "07 Mar 2023",
    url: "/blog/blog-details",
  },
  {
    thumbnailSrc: "/section 7/img2.png",
    title: "Mente artística será ótima para criar qualquer coisa",
    date: "22 Abr 2023",
    url: "/blog/blog-details",
  },
  {
    thumbnailSrc: "/section 7/img3.png",
    title: "IA assumirá todos os empregos humanos em poucos anos",
    date: "13 Mai 2023",
    url: "/blog/blog-details",
  },
  {
    thumbnailSrc: "/section 7/img4.png",
    title: "Sua agência precisa substituir algumas pessoas com mente artística",
    date: "15 Mar 2023",
    url: "/blog/blog-details",
  },
];
const faqData = [
  {
    title: "01. Qual é o modelo legal de investimento?",
    content:
      "De acordo com a lei brasileira CVM 88, nossa plataforma de investimento opera sob o modelo de crowdfunding para investimento em startups e pequenas empresas. Esta regulamentação permite a oferta pública de valores mobiliários por pequenas empresas através de plataformas eletrônicas, fornecendo uma estrutura legal para investidores de varejo participarem de investimentos em estágio inicial.",
  },
  {
    title: "02. Quais são os riscos de investir através da Barzel?",
    content:
      "Como em qualquer investimento, existem riscos envolvidos. Investir em startups e pequenas empresas através de nossa plataforma carrega um alto risco de perda parcial ou total do capital. Os valores mobiliários oferecidos não são garantidos pelo Fundo Garantidor de Crédito (FGC) ou qualquer outro mecanismo de seguro. É importante diversificar seus investimentos e investir apenas o dinheiro que você pode se dar ao luxo de perder.",
  },
  {
    title: "03. Quais são as vantagens de investir através da Barzel?",
    content:
      "Investir através da Barzel oferece várias vantagens: acesso a uma seleção curada de startups e pequenas empresas, capacidade de investir com valores menores em comparação com o capital de risco tradicional, potencial de altos retornos, diversificação de portfólio e oportunidade de apoiar empresas inovadoras. Nossa plataforma também fornece transparência e informações detalhadas sobre cada oportunidade de investimento, conforme exigido pela CVM 88.",
  },
  {
    title: "04. O processo de investimento é totalmente online?",
    content:
      "Sim, todo o processo de investimento é realizado online através de nossa plataforma. Isso inclui navegar por oportunidades de investimento, realizar due diligence, tomar decisões de investimento e gerenciar seu portfólio. No entanto, conforme as regulamentações da CVM 88, certos documentos e confirmações podem precisar ser fornecidos eletronicamente para cumprir os requisitos de verificação do investidor.",
  },
  {
    title: "05. Eu me tornarei acionista da empresa?",
    content:
      "O tipo de título que você recebe depende da oferta específica. Em muitos casos, você receberá notas conversíveis ou outras formas de títulos que podem ser convertidos em ações sob certas condições. Algumas ofertas podem fornecer participação direta. É crucial ler atentamente os termos de cada oportunidade de investimento. Lembre-se que, conforme a CVM 88, seus direitos como investidor, incluindo direitos de voto e direitos econômicos, serão claramente descritos nos documentos da oferta.",
  },
];
const brandList = [
  {
    logoSrc: "/images/marketing-agency/brand_1.svg",
    logoAlt: "Marca",
  },
  {
    logoSrc: "/images/marketing-agency/brand_2.svg",
    logoAlt: "Marca",
  },
  {
    logoSrc: "/images/marketing-agency/brand_3.svg",
    logoAlt: "Marca",
  },
  {
    logoSrc: "/images/marketing-agency/brand_4.svg",
    logoAlt: "Marca",
  },
];
const brandListDark = [
  {
    logoSrc: "/images/marketing-agency/brand_1_dark.svg",
    logoAlt: "Marca",
  },
  {
    logoSrc: "/images/marketing-agency/brand_2_dark.svg",
    logoAlt: "Marca",
  },
  {
    logoSrc: "/images/marketing-agency/brand_3_dark.svg",
    logoAlt: "Marca",
  },
  {
    logoSrc: "/images/marketing-agency/brand_4_dark.svg",
    logoAlt: "Marca",
  },
];

export default function MarketingAgencyPage({ darkMode }) {
  const navigate = useNavigate();
  pageTitle("Início");
  return (
    <>
      <HeroStyle2
        miniTitle="Plataforma de Investimentos Tokenizados"
        title="Faça Grandes Investimentos"
        subTitle="Transforme seu potencial de investimento com nossa plataforma tokenizada - acesse propriedade fracionada em startups premium, imóveis e fundos de VC anteriormente reservados para investidores institucionais."
        thumbnailSrc="/img1.png"
        mikeIcon={
          darkMode ? "/images/icons/mike_dark.svg" : "/images/icons/mike.svg"
        }
      />
      <section className="cs_p76_full_width" id="service">
        <Spacing lg="140" md="75" />
        <SectionHeading title="Projetos" subTitle="Nossos" />
        <Spacing lg="85" md="45" />
        <ServiceSlider data={servideData} />
        <button
          style={{ marginInline: "auto", marginTop: "2rem" }}
          className="cs_btn cs_style_1"
          onClick={() => navigate("/projects")}
        >
          Ver todos
        </button>
      </section>
      <section>
        <div className="cs_height_150 cs_height_lg_75" />
        <AboutStyle2
          thumbnailSrc1="/section 3/img22.png"
          thumbnailSrc2="/section 3/img11.png"
          title="Investimento Simplificado"
          subTitle="Desbloqueie oportunidades de investimento premium em apenas 3 passos simples. Nossa plataforma tokenizada permite que você possua ações em startups de alto potencial com o valor que quiser investir. Zero taxas, zero complicação - apenas potencial de investimento puro com total transparência e liquidez."
          featureList={[
            "Oportunidades de investimento premium",
            "Sem taxas e complicações",
            "Operação totalmente tokenizada",
          ]}
          btnText="Invista agora"
          btnUrl="/register"
        />
      </section>
      <div className="container">
        <Spacing lg="125" md="70" />
        <FunFact data={funfactData} />
      </div>
      <div className="container">
        <Spacing lg="126" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/embed/aSCoGaVvlMs?si=GfWzctiSzsEuHCS"
          bgUrl="/section 4/video.jpg"
          title="Descubra novas oportunidades"
        />
      </div>
      <TestimonialSlider
        layeredImages={[
          "/section 5/img5.png",
          "/section 5/img1.png",
          "/section 5/img3.png",
          "/section 5/img4.png",
          "/section 5/img2.png",
        ]}
        data={testimonialData}
      />
      <section className="cs_primary_bg cs_shape_animation_2">
        <Spacing lg="143" md="75" />
        <div className="cs_shape_1 position-absolute">
          <svg
            width={65}
            height={64}
            viewBox="0 0 65 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <path
                d="M62.4554 25.9314C55.6838 19.6081 40.1618 12.4752 32.1637 20.1537C41.7609 21.9206 53.2379 29.2392 48.3751 39.1677C45.1712 45.7019 38.7353 45.7177 33.3337 41.995C27.338 37.8739 25.7108 31.2667 27.4596 24.5962C26.5312 24.5866 25.6039 24.6605 24.6889 24.8172C9.80991 27.7447 14.0713 47.6353 20.9187 55.948C22.4528 57.8045 19.7488 60.3159 18.1393 58.4837C7.86403 46.8126 6.49349 23.0691 25.5532 19.9295C26.8892 19.7254 28.2446 19.6801 29.5912 19.7945C36.9845 9.42053 56.5698 17.4866 64.055 24.4366C65.1096 25.4175 63.4831 26.8926 62.4554 25.9314ZM33.9938 39.0327C38.3927 42.4636 44.2429 40.8527 44.3919 34.8698C44.6036 28.2263 35.7464 25.0921 29.1457 24.655C27.1454 29.9313 29.4427 35.4836 33.9938 39.0327Z"
                fill="#4F4747"
              />
            </g>
          </svg>
        </div>
        <div className="container">
          <SectionHeading
            title="Por que investir?"
            subTitle="Descubra"
            variantColor="cs_white_color"
          />
          <Spacing lg="85" md="45" />
          <Award data={awardData} />
        </div>
        <Spacing lg="150" md="80" />
      </section>
      <section className="cs_p76_full_width">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="Benefícios de Investir conosco"
            subTitle="Leia mais"
          />
          <Spacing lg="85" md="45" />
        </div>
        <PostCarousel data={postData} />
      </section>
      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Perguntas Frequentes" subTitle="FAQ" />
          <Spacing lg="55" md="30" />
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <Accordion variant="cs_type_1" data={faqData} />
            </div>
          </div>
        </div>
        <Spacing lg="120" md="60" />
      </section>
      <section>
        <div className="container">
          <Cta
            title="Pronto para desbloquear o futuro dos investimentos através da tokenização?"
            btnText="Contate-nos"
            btnUrl="/contact"
            bgUrl="/images/creative-agency/cta_bg.jpeg"
          />
        </div>
      </section>
    </>
  );
}

import React, { useState, useEffect, useRef } from "react";
import MuxPlayer from "@mux/mux-player-react";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import Cta from "../Cta";
import SectionHeading from "../SectionHeading";
import Button from "../Button";
import { pageTitle } from "../../helpers/PageTitle";

const muxPlayerStyles = `
  mux-player {
    --dialog: none;
    --loading-indicator: none;
    --live-button: none;
    --seek-backward-button: none;
    --seek-forward-button: none;
    --mute-button: none;
    --captions-button: none;
    --airplay-button: none;
    --pip-button: none;
    --fullscreen-button: none;
    --cast-button: none;
    --playback-rate-button: none;
    --volume-range: none;
    --time-range: none;
    --time-display: none;
    --duration-display: none;
    --rendition-selectmenu: none;
    --bottom-play-button: none;
    border-radius: 20px;
    width: 100%;
  }
`;

const benefitsList = [
  "Acesso a um portfólio diversificado de ativos do mundo real",
  "Renda passiva mensal através de recompensas de tokens RWA",
  "Contratos inteligentes totalmente transparentes e auditados",
  "Baixa barreira de entrada com opções flexíveis de investimento",
  "Gestão profissional de portfólio",
  "Distribuições mensais automatizadas",
  "Segurança lastreada em ativos",
  "Métricas de desempenho fáceis de acompanhar",
  "Suporte dedicado ao investidor",
];

export default function VslPage({ darkMode }) {
  const [buttonClickable, setButtonClickable] = useState(false);
  const videoRef = useRef(null);

  pageTitle("VSL");

  useEffect(() => {
    const checkTime = () => {
      if (videoRef.current) {
        const currentTime = videoRef.current.currentTime;
        if (currentTime >= 5) {
          setButtonClickable(true);
          return true;
        }
      }
      return false;
    };

    const intervalId = setInterval(() => {
      const shouldStop = checkTime();
      if (shouldStop) clearInterval(intervalId);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <style>{muxPlayerStyles}</style>
      <Spacing lg="100" md="80" />
      <SectionHeadingStyle3
        titleStyle={{ fontSize: "3rem" }}
        title="GANHE RENDA PASSIVA COM TOKENS RWA"
        subTitle="Pronto para fazer seu patrimônio crescer?"
        variant="text-center"
        href="/vsl"
      />

      <section className="container">
        <Spacing lg="35" md="35" />
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <MuxPlayer
                streamType="on-demand"
                nohotkeys
                playbackId="02ScXnld5eFP3ccgHVbm02200c00018vhYfzW8Z5Xy3hkIGg"
                ref={videoRef}
                controls={true}
                poster="https://image.mux.com/02ScXnld5eFP3ccgHVbm02200c00018vhYfzW8Z5Xy3hkIGg/animated.gif?width=320"
              />
            </div>
          </div>
        </div>
        <Spacing lg="85" md="45" />
      </section>

      <section>
        <div className="container">
          <SectionHeading
            title="O QUE VOCÊ VAI RECEBER"
            subTitle="Benefícios do Investimento"
          />
          <Spacing lg="85" md="45" />
          <div className="row align-items-center">
            <div className="col-lg-6">
              <h5 className="cs_fs_21 mb-4">
                Acesse nossa plataforma premium de investimento em tokens RWA
                oferecendo um retorno mensal fixo de 2%. Lastreado por ativos do
                mundo real e gerenciado por especialistas do setor para um
                desempenho ideal.
              </h5>
              <ul className="cs_list_1 cs_mp0">
                {benefitsList.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
            <div className="col-lg-5 offset-lg-1">
              <div className="cs_pricing_card cs_style_1">
                <h3 className="cs_pricing_title">
                  Pacote Premium de Investimento
                </h3>
                <div className="cs_pricing_price">
                  <h4>Investimento Mínimo</h4>
                  <p>começando em</p>
                  <h2>R$500</h2>
                  <p>2% de Retorno Mensal</p>
                </div>
                <Button
                  btnText="Investir Agora"
                  btnUrl="/register"
                  variant={buttonClickable ? "cs_btn_1" : "cs_btn_disabled"}
                />
                <div className="cs_pricing_note">
                  <p>Investimento único - Sem taxas mensais</p>
                  <p>
                    <strong>Acesso vitalício à plataforma</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <Cta
            title="Pronto para começar sua jornada de investimento?"
            btnText="Investir Agora"
            btnUrl="/register"
            bgUrl="/images/creative-agency/cta_bg.jpeg"
          />
        </div>
      </section>
      <Spacing lg="150" md="80" />
    </>
  );
}

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaDownload } from "react-icons/fa";

const DocumentsContainer = styled.div`
  width: 100%;
`;

const Title = styled.h3`
  font-size: 2rem;
  margin-bottom: 20px;
`;

const DocumentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
`;

const DocumentCard = styled.div`
  background-color: #232323;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #d1a546;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: #fff;
  cursor: pointer;
`;

const DocumentTitle = styled.p`
  font-weight: bold;
  margin: 0;
  font-size: 1.2rem;
`;

const DocumentDate = styled.p`
  font-size: 0.9rem;
  color: #b3b3b3;
`;

const DownloadButton = styled.button`
  background: none;
  border: none;
  color: #d1a546;
  cursor: pointer;
  font-size: 1.2rem;
  margin-top: 10px;
  align-self: flex-end;

  &:hover {
    color: #fff;
  }
`;

const Documents = ({ ProjectData }) => {
  const formatDate = (timestamp) => {
    if (!timestamp) return "";

    // Se for um timestamp do Firestore com _seconds
    if (timestamp._seconds) {
      return new Date(timestamp._seconds * 1000).toLocaleDateString("pt-BR");
    }

    // Se for um timestamp regular
    if (timestamp instanceof Date) {
      return timestamp.toLocaleDateString("pt-BR");
    }

    // Se for um número (unix timestamp)
    if (typeof timestamp === "number") {
      return new Date(timestamp).toLocaleDateString("pt-BR");
    }

    return "";
  };

  const documents = [
    {
      title: "Presentation",
      url: ProjectData.presentationUrl,
      date: formatDate(ProjectData.updatedAt),
    },
    {
      title: "Prospectus",
      url: ProjectData.prospectusUrl,
      date: formatDate(ProjectData.updatedAt),
    },
    {
      title: "Due Diligence",
      url: ProjectData.dueDiligenceUrl,
      date: formatDate(ProjectData.updatedAt),
    },
  ];

  const handleDownload = (url) => {
    window.open(url, "_blank");
  };

  return (
    <DocumentsContainer>
      <Title style={{ color: "#BF9A5D" }}>Documents</Title>
      <DocumentGrid>
        {documents.map((doc, index) => (
          <DocumentCard key={index} onClick={() => handleDownload(doc.url)}>
            <DocumentTitle>{doc.title}</DocumentTitle>
            <DocumentDate>Publicado em {doc.date}</DocumentDate>
            <DownloadButton>
              <FaDownload />
            </DownloadButton>
          </DocumentCard>
        ))}
      </DocumentGrid>
    </DocumentsContainer>
  );
};

export default Documents;

import React, { useEffect, useState } from "react";
import LoaderComponent from "../../Loader/Loader";
import SectionHeadingStyle3 from "../../../components/SectionHeading/SectionHeadingStyle3";
import Spacing from "../../../components/Spacing/index";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../context/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../../context/firebase";
import toast from "react-hot-toast";

const MyDeposits = () => {
  const [deposits, setDeposits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingReceipt, setLoadingReceipt] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDeposits = async () => {
      const token = localStorage.getItem("userToken");
      const response = await fetch(
        "https://getuserdeposits-qj6yfdqbiq-uc.a.run.app",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        console.error("Failed to fetch deposits");
        return;
      }

      const data = await response.json();
      const sortedDeposits = data.deposits.sort(
        (a, b) => b.timestamp._seconds - a.timestamp._seconds
      );
      setDeposits(sortedDeposits);
      setLoading(false);
    };

    fetchDeposits();
  }, []);

  const handleReceipt = async (deposit) => {
    setLoadingReceipt(true);
    if (deposit.receipt === "none") {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = ".pdf,image/*";
      input.onchange = async (e) => {
        const file = e.target.files[0];
        if (file) {
          try {
            const token = await auth.currentUser.getIdToken();
            const storageRef = ref(
              storage,
              `users/${deposit.userId}/receipts/${deposit.id}.pdf`
            );
            await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(storageRef);

            const response = await fetch(
              "https://useradddepositreceipt-qj6yfdqbiq-uc.a.run.app",
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  depositId: deposit.id,
                  receiptUrl: downloadURL,
                }),
              }
            );

            if (!response.ok) {
              toast.error("Erro ao adicionar comprovante");
              return;
            }

            toast.success("Comprovante adicionado com sucesso!");
            window.location.reload();
          } catch (error) {
            console.error("Erro ao fazer upload do comprovante:", error);
            toast.error("Erro ao fazer upload do comprovante");
          }
        }
      };
      input.click();
    } else {
      window.open(deposit.receipt, "_blank");
    }
    setLoadingReceipt(false);
  };

  return (
    <>
      <Spacing lg="100" md="80" />
      <SectionHeadingStyle3
        title="Depósitos Efetuados"
        subTitle="Seus"
        variant="text-left"
        href="/transactions"
      />
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="table-responsive">
              <table className="cs_cart_table">
                <thead>
                  <tr>
                    <th style={{ width: "16.66%", textAlign: "center" }}>ID</th>
                    <th style={{ width: "16.66%", textAlign: "center" }}>
                      Data
                    </th>
                    <th style={{ width: "16.66%", textAlign: "center" }}>
                      Hora
                    </th>
                    <th style={{ width: "16.66%", textAlign: "center" }}>
                      Tipo
                    </th>
                    <th style={{ width: "16.66%", textAlign: "center" }}>
                      Valor
                    </th>
                    <th style={{ width: "16.66%", textAlign: "center" }}>
                      Status
                    </th>
                    <th style={{ width: "16.66%", textAlign: "center" }}>
                      Comprovante
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td
                        colSpan="6"
                        style={{ textAlign: "center", padding: "50px 0" }}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "570px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <LoaderComponent />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    deposits.map((deposit, index) => (
                      <tr
                        key={deposit.id}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "transparent" : "#242424",
                        }}
                      >
                        <td style={{ textAlign: "center" }}>
                          {deposit.id.length > 8
                            ? `${deposit.id.slice(0, 4)}...${deposit.id.slice(
                                -4
                              )}`
                            : deposit.id}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {new Date(
                            deposit.timestamp._seconds * 1000
                          ).toLocaleDateString("pt-BR")}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {new Date(
                            deposit.timestamp._seconds * 1000
                          ).toLocaleTimeString("pt-BR")}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {deposit.type === "deposit" ? "Depósito" : "PIX"}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(deposit.amount)}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            color:
                              deposit.status === "confirmed"
                                ? "#00FF00"
                                : deposit.status === "pending"
                                ? "#14FFFF"
                                : "inherit",
                          }}
                        >
                          {deposit.status}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <button
                            style={{ width: "100%" }}
                            onClick={() => handleReceipt(deposit)}
                            className={
                              deposit.receipt === "none"
                                ? "cs_btn cs_style_1 cs_btn_accent"
                                : "cs_btn cs_style_1"
                            }
                          >
                            {loadingReceipt
                              ? "Adicionando..."
                              : deposit.receipt === "none"
                              ? "Adicionar"
                              : "Visualizar"}
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyDeposits;

import React, { useState, useEffect, useRef } from "react";
import MuxPlayer from "@mux/mux-player-react";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import Cta from "../Cta";
import SectionHeading from "../SectionHeading";
import Button from "../Button";
import { pageTitle } from "../../helpers/PageTitle";
import axios from "axios";
import toast from "react-hot-toast";
import FunFact from "../FunFact";
import { useParams } from "react-router-dom";
const muxPlayerStyles = `
  mux-player {
    --dialog: none;
    --loading-indicator: none;
    --live-button: none;
    --seek-backward-button: none;
    --seek-forward-button: none;
    --mute-button: none;
    --captions-button: none;
    --airplay-button: none;
    --pip-button: none;
    --fullscreen-button: none;
    --cast-button: none;
    --playback-rate-button: none;
    --volume-range: none;
    --time-range: none;
    --time-display: none;
    --duration-display: none;
    --rendition-selectmenu: none;
    --bottom-play-button: none;
    border-radius: 20px;
    width: 100%;
  }
`;

const benefitsList = [
  "Acesso a um portfólio diversificado de ativos do mundo real",
  "Renda passiva mensal através de recompensas de tokens RWA",
  "Contratos inteligentes totalmente transparentes e auditados",
  "Baixa barreira de entrada com opções flexíveis de investimento",
  "Gestão profissional de portfólio",
  "Distribuições mensais automatizadas",
  "Segurança lastreada em ativos",
  "Métricas de desempenho fáceis de acompanhar",
  "Suporte dedicado ao investidor",
];

const funfactData = [
  { title: "Investido", number: "372M" },
  { title: "Projetos Lançados", number: "65" },
  { title: "Investidores", number: "1k" },
  { title: "Retorno Anual", number: "+24%" },
];

export default function VslPage({ darkMode }) {
  const { vslId } = useParams();
  const [buttonClickable, setButtonClickable] = useState(false);
  const videoRef = useRef(null);
  const [ebookForm, setEbookForm] = useState({
    name: "",
    email: "",
    number: "",
    value: "",
    vslId: vslId,
  });

  pageTitle("VSL");

  useEffect(() => {
    setEbookForm({ ...ebookForm, vslId: vslId });
  }, [vslId]);

  useEffect(() => {
    const checkTime = () => {
      if (videoRef.current) {
        const currentTime = videoRef.current.currentTime;
        if (currentTime >= 5) {
          setButtonClickable(true);
          return true;
        }
      }
      return false;
    };

    const intervalId = setInterval(() => {
      const shouldStop = checkTime();
      if (shouldStop) clearInterval(intervalId);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleSubmitEbook = async (e) => {
    e.preventDefault();

    // Basic validation
    if (
      !ebookForm.name ||
      !ebookForm.email ||
      !ebookForm.number ||
      !ebookForm.value
    ) {
      toast.error("Por favor, preencha todos os campos");
      return;
    }

    try {
      const response = await axios.post("YOUR_API_ENDPOINT", ebookForm);

      if (response.data.success) {
        toast.success("E-book enviado com sucesso!");
        // Reset form
        setEbookForm({
          name: "",
          email: "",
          number: "",
          value: "",
        });

        // You can add logic here to trigger the ebook download
        // window.open(response.data.ebookUrl, '_blank');
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Erro ao enviar formulário. Tente novamente.");
    }
  };

  return (
    <>
      <style>{muxPlayerStyles}</style>
      <Spacing lg="100" md="80" />
      <SectionHeadingStyle3
        titleStyle={{ fontSize: "3rem" }}
        title="GANHE RENDA PASSIVA COM TOKENS RWA"
        subTitle="Pronto para fazer seu patrimônio crescer?"
        variant="text-center"
        href="/ebook"
      />

      <section className="container">
        <Spacing lg="35" md="35" />
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <MuxPlayer
                streamType="on-demand"
                nohotkeys
                playbackId="02ScXnld5eFP3ccgHVbm02200c00018vhYfzW8Z5Xy3hkIGg"
                ref={videoRef}
                controls={true}
                poster="https://image.mux.com/02ScXnld5eFP3ccgHVbm02200c00018vhYfzW8Z5Xy3hkIGg/animated.gif?width=320"
              />
            </div>
          </div>
        </div>
        <Spacing lg="80" md="45" />
      </section>

      <section className="container">
        <div className="row flex-column-reverse flex-lg-row">
          <div className="col-lg-6 d-flex flex-column justify-content-center">
            <h2 className="cs_fs_68 mb-4">Baixe nosso E-book Gratuito</h2>
            <p className="cs_fs_21 mb-4">
              Descubra como os tokens RWA estão revolucionando o mercado de
              investimentos e aprenda estratégias para maximizar seus
              rendimentos com ativos do mundo real.
            </p>
            <ul className="cs_list_1 cs_mp0">
              <li>Guia completo sobre tokens RWA</li>
              <li>Estratégias de investimento detalhadas</li>
              <li>Cases de sucesso e análises de mercado</li>
              <li>Dicas exclusivas dos nossos especialistas</li>
            </ul>
          </div>
          <div className="col-lg-5 offset-lg-1">
            <form
              className="cs_contact_form cs_style_1"
              onSubmit={handleSubmitEbook}
            >
              <div className="cs_form_field_wrap">
                <label className="cs_input_label">Nome</label>
                <input
                  type="text"
                  className="cs_form_field"
                  placeholder="Digite seu nome"
                  value={ebookForm.name}
                  onChange={(e) =>
                    setEbookForm({ ...ebookForm, name: e.target.value })
                  }
                />
                <Spacing lg="20" md="20" />

                <label className="cs_input_label">Email</label>
                <input
                  type="email"
                  className="cs_form_field"
                  placeholder="Digite seu email"
                  value={ebookForm.email}
                  onChange={(e) =>
                    setEbookForm({ ...ebookForm, email: e.target.value })
                  }
                />
                <Spacing lg="20" md="20" />

                <label className="cs_input_label">Telefone</label>
                <input
                  type="tel"
                  className="cs_form_field"
                  placeholder="Digite seu telefone"
                  value={ebookForm.number}
                  onChange={(e) =>
                    setEbookForm({ ...ebookForm, number: e.target.value })
                  }
                />
                <Spacing lg="20" md="20" />

                <label className="cs_input_label">
                  Valor disponível para investir hoje
                </label>
                <input
                  type="number"
                  className="cs_form_field"
                  placeholder="Digite o valor"
                  value={ebookForm.value}
                  onChange={(e) =>
                    setEbookForm({ ...ebookForm, value: e.target.value })
                  }
                />
                <Spacing lg="30" md="30" />

                <Button
                  btnText="Baixar E-book"
                  variant="cs_btn_1"
                  type="submit"
                  style={{ width: "100%" }}
                />
              </div>
            </form>
          </div>
        </div>
        <Spacing lg="100" md="45" />
      </section>

      <section className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <img
              src="https://placehold.co/600x400"
              alt="Pessoa Física"
              className="img-fluid rounded"
            />
          </div>
          <div className="col-lg-6">
            <div className="cs_content_box_1">
              <h2>Pessoa Física</h2>
              <p>
                Caso você ainda esteja em fase de construção de patrimônio, pode
                perfeitamente acessar o mercado internacional — e assim adquirir
                ativos globalizados — como pessoa física, sem a necessidade de
                abrir um CNPJ no exterior.
              </p>
            </div>
          </div>
        </div>
        <Spacing lg="50" md="30" />
        <div className="row align-items-center">
          <div className="col-lg-6 order-lg-2">
            <img
              src="https://placehold.co/600x400"
              alt="Pessoa Jurídica"
              className="img-fluid rounded"
            />
          </div>
          <div className="col-lg-6 order-lg-1">
            <div className="cs_content_box_1">
              <h2>Pessoa Jurídica</h2>
              <p>
                Tendo um patrimônio expressivo, é fundamental que você considere
                ter uma estrutura offshore, passando a contar com eficiência
                tributária, maior diversificação do seu portfólio e estratégia
                sucessória personalizada.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container">
        <Spacing lg="100" md="45" />
        <div className="row align-items-center">
          <div className="col-lg-4">
            <img
              src="https://placehold.co/400x700"
              alt="Investimentos Offshore"
              className="img-fluid rounded"
            />
          </div>
          <div className="col-lg-8">
            <div className="cs_content_box_1 ps-lg-5">
              <h2 className="cs_fs_48 mb-4">
                Diversifique seus Investimentos Globalmente
              </h2>
              <p className="cs_fs_21 mb-4">
                A diversificação internacional de investimentos é uma estratégia
                fundamental para proteger e fazer crescer seu patrimônio no
                longo prazo. Ao investir em diferentes mercados e jurisdições,
                você não apenas reduz riscos, mas também aproveita oportunidades
                globais de crescimento.
              </p>
              <p className="mb-4">
                Com nossa expertise em investimentos offshore, você terá acesso
                a:
              </p>
              <ul className="cs_list_1 cs_mp0 mb-4">
                <li>Portfólio diversificado em múltiplas moedas</li>
                <li>Acesso a mercados desenvolvidos e emergentes</li>
                <li>Proteção contra instabilidades econômicas locais</li>
                <li>Estruturas fiscalmente eficientes</li>
                <li>Planejamento sucessório internacional</li>
              </ul>
              <p className="mb-5">
                Nossa equipe de especialistas está pronta para guiá-lo em cada
                passo do processo, desde a estruturação inicial até a gestão
                contínua dos seus investimentos internacionais.
              </p>
              <Button btnText="Fale com um Especialista" variant="cs_btn_1" />
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <Spacing lg="125" md="70" />
        <FunFact data={funfactData} />
      </div>

      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <Cta
            title="Pronto para começar sua jornada de investimento?"
            btnText="Investir Agora"
            btnUrl="/register"
            bgUrl="/images/creative-agency/cta_bg.jpeg"
          />
        </div>
      </section>
      <Spacing lg="45" md="45" />
    </>
  );
}

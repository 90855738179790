import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import SectionHeadingStyle3 from "../../SectionHeading/SectionHeadingStyle3";
import Spacing from "../../Spacing";
import { UserContext } from "../../../context/UserContext";
import LoaderComponent from "../../Loader/Loader";

const Dashboard = () => {
  const { userBalance, cryptoPrices, updateUserBalance } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [sharesBalance, setSharesBalance] = useState(0);

  useEffect(() => {
    const fetchShares = async () => {
      try {
        const token = localStorage.getItem("userToken");

        if (!token) return;

        const response = await fetch(
          `https://getusershares-qj6yfdqbiq-uc.a.run.app`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        
        // console.log('Shares data:', data);
        // console.log('Shares array:', data.shares);

        const sharesTotal = data.shares.reduce((acc, share) => {
          // console.log('Share:', share);
          return acc + share.numberOfShares * share.sharePrice;
        }, 0);

        // console.log('Shares total:', sharesTotal);
        
        setSharesBalance(sharesTotal);

        updateUserBalance((prev) => {
          const newBalance = {
            ...prev,
            shares: sharesTotal,
          };
          // console.log('Updated userBalance:', newBalance);
          return newBalance;
        });
      } catch (error) {
        console.error("Erro ao buscar ações:", error);
      }
    };

    fetchShares();
  }, [updateUserBalance]);

  useEffect(() => {
    // console.log('Current userBalance:', userBalance);
  }, [userBalance]);

  const calculateTotal = () => {
    try {
      if (
        !cryptoPrices?.btc ||
        !cryptoPrices?.usdt ||
        !userBalance ||
        typeof userBalance.btc !== "number" ||
        typeof userBalance.usdt !== "number" ||
        typeof userBalance.brl !== "number"
      ) {
        // console.log('UserBalance:', userBalance);
        // console.log('CryptoPrices:', cryptoPrices);
        return "0.00";
      }

      const btcInBRL = userBalance.btc * Number(cryptoPrices.btc);
      const usdtInBRL = userBalance.usdt * (cryptoPrices.usdt / 100);
      const sharesInBRL = sharesBalance;
      const brlBalance = userBalance.brl;

      const total = brlBalance + btcInBRL + usdtInBRL + sharesInBRL;

      // console.log('Calculated values:', {
      //   brlBalance,
      //   btcInBRL,
      //   usdtInBRL,
      //   sharesInBRL,
      //   total
      // });

      return total.toFixed(2);
    } catch (err) {
      console.error("Erro ao calcular total:", err);
      return "0.00";
    }
  };

  return (
    <>
      <Spacing lg="100" md="80" />
      <SectionHeadingStyle3
        title="Dashboard"
        subTitle="Gerencie seus saldos"
        variant="text-left"
      />
      <InvestmentsPage>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
              flexDirection: "column",
            }}
          >
            <h4>Carregando...</h4>
            <LoaderComponent size={80} borderThickness={5} />
          </div>
        ) : error ? (
          <ErrorMessage>{error}</ErrorMessage>
        ) : (
          <InvestmentCard>
            <CardHeader>Saldo Atual</CardHeader>
            <CardBody>
              <BalanceItem
                label="Saldo BRL"
                value={`R$ ${userBalance.brl.toFixed(2)}`}
              />
              <Divider />
              <BalanceItem
                label="Saldo BTC"
                value={`₿ ${userBalance.btc.toFixed(8)}`}
              />
              <Divider />
              <BalanceItem
                label="Saldo USDT"
                value={`$ ${userBalance.usdt.toFixed(2)}`}
              />
              <Divider />
              <BalanceItem
                label="Saldo em Ações"
                value={`R$ ${(sharesBalance || userBalance.shares || 0).toFixed(2)}`}
              />
              <Divider />
              <BalanceItem
                label="Total em BRL"
                value={`R$ ${calculateTotal()}`}
              />
            </CardBody>
          </InvestmentCard>
        )}
      </InvestmentsPage>
    </>
  );
};

const BalanceItem = ({ label, value }) => (
  <div>
    <p>
      <strong>{label}:</strong>
    </p>
    <Value>{value}</Value>
  </div>
);

// Estilos aprimorados sem alteração de cores
const InvestmentsPage = styled.section`
  padding: 20px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #171717;
  min-height: 100vh;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const InvestmentCard = styled.article`
  background-color: #1f1f1f;
  border: 1px solid #c89c5c;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Suave sombra para destaque */
  padding: 30px;
  min-width: 350px;
  text-align: center;

  @media (max-width: 768px) {
    width: 90%;
    min-width: 300px;
  }
`;

const CardHeader = styled.header`
  background-color: #c89c5c;
  color: #fff;
  font-weight: bold;
  font-size: 1.5em;
  text-align: center;
  padding: 15px 0;
  border-radius: 8px 8px 0 0;
  letter-spacing: 1px;
`;

const CardBody = styled.div`
  padding: 20px 0;

  p {
    margin: 10px 0;
    color: #e0e0e0;
  }
`;

const Value = styled.p`
  font-size: 2.5em;
  color: #cccccc;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4); /* Sutil para profundidade */

  @media (max-width: 768px) {
    font-size: 2em;
  }
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #c89c5c;
  margin: 20px 0;
  width: 85%;
`;

const LoadingMessage = styled.p`
  color: #ffc107;
  font-size: 1.2em;
  text-shadow: 0px 0px 4px rgba(255, 193, 7, 0.6);
`;

const ErrorMessage = styled.p`
  color: #ff6b6b;
  font-size: 1.2em;
  font-weight: bold;
  text-shadow: 0px 0px 4px rgba(255, 107, 107, 0.6);
`;

export default Dashboard;

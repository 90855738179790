// useTokenRefresh.js
import { useEffect } from 'react';
import { auth } from './firebase'; // Certifique-se de importar auth corretamente

const useTokenRefresh = () => {
  useEffect(() => {
    const refreshTokenInterval = setInterval(async () => {
      const user = auth.currentUser;

      if (user) {
        try {
          // Força a atualização do token de ID
          await user.getIdToken(true);
          // console.log('Token de ID atualizado.');
          // console.log('Token de ID:', await user.getIdToken());
        } catch (error) {
          // console.error('Erro ao atualizar o token:', error);
        }
      }
    }, 15 * 60 * 1000); // Atualiza a cada 15 minutos

    return () => clearInterval(refreshTokenInterval); // Limpa o intervalo ao desmontar o componente
  }, []);
};

export default useTokenRefresh;
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Spacing from "../../Spacing";
import SectionHeadingStyle3 from "../../SectionHeading/SectionHeadingStyle3";
import LoaderComponent from "../../Loader/Loader";
import { toast } from "react-hot-toast";
import { getUserBalance } from "../investments/GetUserBalance";

const DepositAdmin = ({ darkMode }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchBalance = async () => {
      const userBalance = await getUserBalance();
      if (userBalance !== null) {
        console.log("Saldo do usuário:", userBalance);
      }
    };

    fetchBalance();
  }, []);

  // Obtém página da URL ou usa 1 como padrão
  const queryParams = new URLSearchParams(location.search);
  const pageFromUrl = parseInt(queryParams.get("page")) || 1;

  const [deposits, setDeposits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(pageFromUrl);
  const [totalPages, setTotalPages] = useState(1);

  // Atualiza URL quando a página muda
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    navigate(`/transactions?page=${newPage}&limit=10`);
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("userToken");

        if (!token) {
          toast.error("Você precisa estar logado para ver as transações");
          return;
        }

        const response = await fetch(
          `https://getdepositslist-qj6yfdqbiq-uc.a.run.app`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        console.log("Resposta da API:", data);
        const pendingDeposits = data.deposits.filter(
          (deposit) => deposit.status === "pending"
        );
        setDeposits(pendingDeposits);
        setTotalPages(data.pagination.totalPages);
      } catch (error) {
        console.error("Erro ao buscar transações:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [currentPage]);

  const formatDate = (timestamp) => {
    return new Date(timestamp._seconds * 1000).toLocaleDateString();
  };

  const formatAmount = (amount, type) => {
    const formattedAmount = Math.round(Math.abs(amount));
    return `R$${formattedAmount}`;
  };

  const handleConfirm = async (depositId) => {
    try {
      const token = localStorage.getItem("userToken");

      if (!token) {
        toast.error("Você precisa estar logado para confirmar o depósito");
        return;
      }

      const response = await fetch(
        `https://adminconfirmuserdeposit-qj6yfdqbiq-uc.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            depositId: depositId,
            action: "confirm",
          }),
        }
      );

      console.log("Resposta da API:", response);

      if (response.ok) {
        const result = await response.json();
        toast.success(result.message || "Depósito confirmado com sucesso");
        // Atualizar a lista de depósitos
        setDeposits((prevDeposits) =>
          prevDeposits.filter((deposit) => deposit.id !== depositId)
        );
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || "Erro ao confirmar o depósito");
      }
    } catch (error) {
      console.error("Erro ao confirmar o depósito:", error);
      toast.error("Erro ao confirmar o depósito");
    }
  };

  const handleReject = async (depositId) => {
    try {
      const token = localStorage.getItem("userToken");

      if (!token) {
        toast.error("Você precisa estar logado para reprovar o depósito");
        return;
      }

      const response = await fetch(
        `https://adminconfirmuserdeposit-qj6yfdqbiq-uc.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            depositId: depositId,
            action: "reject",
          }),
        }
      );

      console.log("Resposta da API:", response);

      if (response.ok) {
        const result = await response.json();
        toast.success(result.message || "Depósito reprovado com sucesso");
        // Atualizar a lista de depósitos
        setDeposits((prevDeposits) =>
          prevDeposits.filter((deposit) => deposit.id !== depositId)
        );
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || "Erro ao reprovar o depósito");
      }
    } catch (error) {
      console.error("Erro ao reprovar o depósito:", error);
      toast.error("Erro ao reprovar o depósito");
    }
  };

  const handleDownload = (url) => {
    if (url !== "none") {
      window.open(url, "_blank");
    } else {
      toast.error("Não há um comprovante para este depósito");
    }
  };

  return (
    <>
      <Spacing lg="100" md="80" />
      <SectionHeadingStyle3
        title="Deposit Admin"
        subTitle="administration"
        variant="text-left"
        href="/deposit-admin"
      />
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="table-responsive">
              <table className="cs_cart_table">
                <thead>
                  <tr>
                    <th style={{ width: "20%", textAlign: "center" }}>Email</th>
                    <th style={{ width: "20%", textAlign: "center" }}>CPF</th>
                    <th style={{ width: "20%", textAlign: "center" }}>Data</th>
                    <th style={{ width: "20%", textAlign: "center" }}>Valor</th>
                    <th style={{ width: "20%", textAlign: "center" }}>
                      Comprovante
                    </th>
                    <th style={{ width: "20%", textAlign: "center" }}>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td
                        colSpan="6"
                        rowSpan="10"
                        style={{ textAlign: "center", padding: "50px 0" }}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "570px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <LoaderComponent />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    deposits.map((deposit, index) => (
                      <tr
                        key={deposit.id}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "transparent" : "#242424",
                        }}
                      >
                        <td style={{ width: "20%", textAlign: "center" }}>
                          {deposit.userEmail}
                        </td>
                        <td style={{ width: "20%", textAlign: "center" }}>
                          {deposit.userCpf}
                        </td>
                        <td style={{ width: "20%", textAlign: "center" }}>
                          {formatDate(deposit.timestamp)}
                        </td>
                        <td style={{ width: "20%", textAlign: "center" }}>
                          {formatAmount(deposit.amount, deposit.type)}
                        </td>
                        <td style={{ width: "20%", textAlign: "center" }}>
                          <button
                            onClick={() => handleDownload(deposit.receipt)}
                            disabled={deposit.receipt === "none"}
                            style={{
                              background:
                                deposit.receipt === "none"
                                  ? "#a9a9a9"
                                  : "#007bff",
                              padding: "10px 20px",
                              border: "none",
                              width: "100%",
                              display: "block",
                              color: "white",
                              fontWeight: "bold",
                              cursor:
                                deposit.receipt === "none"
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                          >
                            Ver Comprovante
                          </button>
                        </td>
                        <td style={{ width: "20%", textAlign: "center" }}>
                          <button
                            onClick={() => handleConfirm(deposit.id)}
                            style={{
                              background: "#11C160",
                              padding: "10px 20px",
                              border: "none",
                              width: "100%",
                              display: "block",
                              color: "white",
                              fontWeight: "bold",
                              marginBottom: "10px",
                            }}
                          >
                            Confirmar
                          </button>
                          <button
                            onClick={() => handleReject(deposit.id)}
                            style={{
                              background: "#E83238",
                              padding: "10px 20px",
                              border: "none",
                              width: "100%",
                              display: "block",
                              color: "white",
                              fontWeight: "bold",
                            }}
                          >
                            Reprovar
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>

            {/* Paginação */}
            <div
              className="cs_pagination"
              style={{
                textAlign: "center",
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "30px",
              }}
            >
              <button
                onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                disabled={currentPage === 1}
                className="cs_btn cs_style_1"
                style={{ width: "100px" }}
              >
                Anterior
              </button>
              <span>
                Página {currentPage} de {totalPages}
              </span>
              <button
                onClick={() =>
                  handlePageChange(Math.min(currentPage + 1, totalPages))
                }
                disabled={currentPage === totalPages}
                className="cs_btn cs_style_1"
                style={{ width: "100px" }}
              >
                Próxima
              </button>
            </div>

            <div className="cs_height_30 cs_height_lg_30" />
          </div>
        </div>
      </div>
    </>
  );
};

export default DepositAdmin;

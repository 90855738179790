import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";
const addressDataList = [
  "Av. Brg. Faria Lima, 3477 - Itaim Bibi",
  "+55 82 99635 0992",
  "contact@barzel.com",
];
const serviceMenuList = [
  {
    title: "Desenvolvimento WP",
    href: "/service/service-details",
  },
  {
    title: "Pesquisa UX",
    href: "/service/service-details",
  },
  {
    title: "Design de Marca",
    href: "/service/service-details",
  },
  {
    title: "Desenvolvimento Front-End",
    href: "/service/service-details",
  },
  {
    title: "Design Gráfico",
    href: "/service/service-details",
  },
  {
    title: "Promoção de Anúncios",
    href: "/service/service-details",
  },
];
const LinksMenuList = [
  {
    title: "Início",
    href: "/",
  },
  {
    title: "Sobre",
    href: "/about",
  },
  // {
  //   title: 'Serviços',
  //   href: '/service',
  // },
  // {
  //   title: 'Portfólio',
  //   href: 'portfolio',
  // },
  {
    title: "Blog",
    href: "/blog",
  },
  {
    title: "Contato",
    href: "/contact",
  },
];
const policyMenuList = [
  {
    title: "Termos de Uso",
    href: "/",
  },
  {
    title: "Política de Privacidade",
    href: "/",
  },
];
const socialBtnList = [
  {
    icon: "fa6-brands:linkedin-in",
    href: "/",
  },
  {
    icon: "fa6-brands:twitter",
    href: "/",
  },
  {
    icon: "fa6-brands:youtube",
    href: "/",
  },
  {
    icon: "fa6-brands:facebook-f",
    href: "/",
  },
];
export default function Footer() {
  return (
    <footer
      className="cs_fooer cs_bg_filed"
      style={{
        backgroundImage: "url(/images/footer_bg.jpeg)",
        marginTop: "100px",
      }}
    >
      <div className="cs_fooer_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="cs_footer_item">
                <div className="cs_text_widget">
                  <img
                    style={{ width: "200px" }}
                    src="/barzel-logo.png"
                    alt="Logo"
                  />
                </div>
                <ul className="cs_menu_widget cs_mp0">
                  {addressDataList.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
              <img src="/CVM.png" alt="Logo" style={{ width: "150px" }} />
            </div>
            <div className="col-lg-3 col-sm-6">
              {/* <div className="cs_footer_item">
                <h2 className="cs_widget_title">Serviços</h2>
                <ul className="cs_menu_widget cs_mp0">
                  {serviceMenuList.map((item, index) => (
                    <li key={index}>
                      <Link to={item.href}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div> */}
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="cs_footer_item">
                <h2 className="cs_widget_title">Links</h2>
                <ul className="cs_menu_widget cs_mp0">
                  {LinksMenuList.map((item, index) => (
                    <li key={index}>
                      <Link to={item.href}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="cs_footer_item">
                <h2 className="cs_widget_title">Assine Nossa Newsletter</h2>
                <div className="cs_newsletter cs_style_1">
                  <div className="cs_newsletter_text">
                    Garantimos enviar apenas e-mails relevantes e dignos de nota
                    para você.
                  </div>
                  <form action="#" className="cs_newsletter_form">
                    <input
                      type="email"
                      className="cs_newsletter_input"
                      placeholder="Endereço de e-mail"
                    />
                    <button className="cs_btn cs_style_1">
                      Enviar
                      <span>
                        <i>
                          <Icon icon="fa6-solid:arrow-right" />
                        </i>
                        <i>
                          <Icon icon="fa6-solid:arrow-right" />
                        </i>
                      </span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="cs_bottom_footer">
          <div className="cs_bottom_footer_left">
            <div className="cs_social_btns cs_style_1">
              {socialBtnList.map((item, index) => (
                <Link to={item.href} className="cs_center" key={index}>
                  <Icon icon={item.icon} />
                </Link>
              ))}
            </div>
          </div>
          <div className="cs_copyright">Direitos Autorais © 2024 Barzel.</div>
          <div className="cs_bottom_footer_right">
            <ul className="cs_footer_links cs_mp0">
              {policyMenuList.map((item, index) => (
                <li key={index}>
                  <Link to={item.href}>{item.title}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

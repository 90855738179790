import React, { useEffect, useState, useContext } from "react";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import { pageTitle } from "../../helpers/PageTitle";
import { Icon } from "@iconify/react";
import { signInWithEmailAndPassword } from "firebase/auth";
import LoaderComponent from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import { auth } from "../../context/firebase";
import toast from "react-hot-toast";
import { UserContext } from "../../context/UserContext";
export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [divHeight, setDivHeight] = useState(800);
  const { updateLoginStatus } = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    const updateHeight = () => {
      setDivHeight(window.innerWidth < 1000 ? 450 : 800);
    };

    updateHeight(); // Definir altura ao montar o componente
    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          updateLoginStatus(true);
          user.getIdToken().then((token) => {
            const existingToken = localStorage.getItem("userToken");
            if (existingToken) {
              localStorage.removeItem("userToken");
              localStorage.removeItem("tokenExpiration");
            }
            const expirationTime = new Date().getTime() + 3 * 60 * 60 * 1000;
            localStorage.setItem("userToken", token);
            localStorage.setItem("tokenExpiration", expirationTime);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
            navigate("/");
          });
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Credenciais inválidas");
          console.error("Erro de login:", error);
        });
    } catch (error) {
      setLoading(false);
      console.error("Erro inesperado:", error);
    }
  };

  pageTitle("Entrar");
  return (
    <>
      {loading && <LoaderComponent fullPage={true} />}
      <Spacing lg="90" md="90" />
      <section>
        <div className="container">
          <div className="row align-items-center cs_gap_y_45">
            <div className="col-lg-6">
              <div style={{ marginBlock: "auto" }}>
                <SectionHeadingStyle3
                  title="Entrar"
                  subTitle="Acessar"
                  shape="shape_6"
                />
                <Spacing lg="90" md="0" />
              </div>
            </div>
            <div style={{ marginBottom: "-6.2rem" }} className="col-lg-6">
              <div
                style={{
                  height: `${divHeight}px`,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
                className="cs_contact_form_wrap"
              >
                <div className="cs_gray_bg_3 cs_contact_form_bg" />
                <form className="cs_contact_form">
                  <label className="cs_fs_21 cs_semibold cs_primary_color">
                    Email
                  </label>
                  <input
                    placeholder="Seu email"
                    type="text"
                    className="cs_form_field"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <label className="cs_fs_21 cs_semibold cs_primary_color">
                    Senha
                  </label>
                  <input
                    placeholder="Sua senha"
                    type="password"
                    className="cs_form_field"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <button
                      onClick={(e) => handleLogin(e)}
                      className="cs_btn cs_style_1"
                    >
                      Entrar{" "}
                      <span>
                        <i>
                          <Icon icon="fa6-solid:arrow-right" />
                        </i>
                        <i>
                          <Icon icon="fa6-solid:arrow-right" />
                        </i>
                      </span>
                    </button>
                    <span
                      onClick={(e) => navigate("/change-password")}
                      style={{ cursor: "pointer" }}
                    >
                      Esqueceu a senha
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="cs_height_0 cs_height_lg_80" />
      </section>
    </>
  );
}

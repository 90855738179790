import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SectionHeadingStyle3 from "../../SectionHeading/SectionHeadingStyle3";
import styled from "styled-components";
import Spacing from "../../Spacing";

const Investments = () => {
  const navigate = useNavigate();
  const [balances, setBalances] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState({});
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    const fetchBalances = async () => {
      // Geração de valores aleatórios altos
      const getRandomValue = () => `R$${(Math.random() * 100000).toFixed(2)}`;

      const fetchedBalances = [
        {
          name: "CARTEIRA RWA",
          balance: getRandomValue(),
          contractsValue: getRandomValue(),
          income: getRandomValue(),
        },
        {
          name: "CARTEIRA DIVIDENDOS",
          balance: getRandomValue(),
          contractsValue: getRandomValue(),
          income: getRandomValue(),
        },
        {
          name: "CARTEIRA USDT",
          balance: getRandomValue(),
          contractsValue: getRandomValue(),
          income: getRandomValue(),
        },
        {
          name: "CARTEIRA BTC",
          balance: getRandomValue(),
          contractsValue: getRandomValue(),
          income: getRandomValue(),
        },
      ];
      setBalances(fetchedBalances);
    };

    fetchBalances();
  }, []);

  const toggleDropdown = (walletIndex, itemIndex) => {
    setDropdownVisible((prevState) => {
      const newState = {};
      Object.keys(prevState).forEach((key) => {
        newState[key] = false;
      });
      newState[`${walletIndex}-${itemIndex}`] =
        !prevState[`${walletIndex}-${itemIndex}`];
      return newState;
    });
  };

  const handleCardClick = (index) => {
    setSelectedCard(index === selectedCard ? null : index);
  };

  return (
    <>
      <Spacing lg="100" md="80" />
      <SectionHeadingStyle3
        title="Investimentos"
        subTitle="Seus"
        variant="text-left"
      />
      <InvestmentsPage>
        {/* <Header>
          <Actions>
            <Button className="cs_style_1">Meus Contratos</Button>
            <Button className="cs_style_2">Novo Depósito</Button>
          </Actions>
        </Header> */}
        <InvestmentsGrid>
          {balances.map((wallet, walletIndex) => (
            <InvestmentCard key={walletIndex}>
              <CardHeader>{wallet.name}</CardHeader>
              <CardBody>
                <p>
                  <strong>Saldo Disponível:</strong>
                </p>
                <Value>{wallet.balance}</Value>
                <Divider />
                <p>
                  <strong>Visão Geral do Contrato:</strong>
                </p>
                <p>Valor Total dos Contratos:</p>
                <Value>{wallet.contractsValue}</Value>
                <Divider2 />
                <p>Renda Total a Receber:</p>
                <Value>{wallet.income}</Value>
              </CardBody>
              <CardFooter>
                {["BARZELL SEC", "BARZELL EXP"].map((item, itemIndex) => (
                  <FooterItem
                    key={itemIndex}
                    expanded={dropdownVisible[`${walletIndex}-${itemIndex}`]}
                  >
                    <FooterItemHeader
                      onClick={() => toggleDropdown(walletIndex, itemIndex)}
                    >
                      <span>{item}</span>
                      <DropdownIcon>▼</DropdownIcon>
                    </FooterItemHeader>
                    <Divider2 style={{ width: "100%", marginTop: "15px" }} />
                    {dropdownVisible[`${walletIndex}-${itemIndex}`] && (
                      <DropdownMenu style={{ marginTop: "-15px" }}>
                        <DropdownItem>Opção 1</DropdownItem>
                        <DropdownItem>Opção 2</DropdownItem>
                        <DropdownItem>Opção 3</DropdownItem>
                      </DropdownMenu>
                    )}
                  </FooterItem>
                ))}
              </CardFooter>
            </InvestmentCard>
          ))}
        </InvestmentsGrid>
      </InvestmentsPage>
    </>
  );
};

const InvestmentsPage = styled.div`
  padding: 20px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 50px;

  @media (max-width: 425px) {
    justify-content: center;
  }
`;

const Actions = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  max-width: 150px;
  font-weight: bold;

  &.cs_style_1 {
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
  }

  &.cs_style_2 {
    background-color: #b3832c;
    color: #fff;
  }

  @media (max-width: 340px) {
    font-size: 12px;
  }
`;

const InvestmentsGrid = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 20px;
  justify-content: center;
  width: 100%;

  @media (max-width: 1275px) {
    grid-auto-flow: row;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
`;

const InvestmentCard = styled.div`
  background-color: #1f1f1f;
  border: 1px solid #c89c5c;
  border-radius: 8px;
  overflow: hidden;
  padding: 15px;
  min-width: 300px;
  text-align: center;
`;

const CardHeader = styled.div`
  background-color: #c89c5c;
  color: #fff;
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
`;

const CardBody = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid #c89c5c;

  p {
    margin: 5px 0;
  }
`;

const Value = styled.p`
  font-size: 2em;
  color: #cccccc;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
`;

const Divider = styled.hr`
  border: none;
  align-self: center;
  justify-self: center;
  border-top: 1px solid #c89c5c;
  margin: 10px 0;
  width: 85%;
`;

const Divider2 = styled.hr`
  border: none;
  align-self: center;
  justify-self: center;
  border-top: 2px solid gray;
  margin: 10px 0;
  width: 95%;
`;

const CardFooter = styled.div`
  padding: 10px 0;
  text-align: left;
`;

const FooterItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 5px 0;
  position: relative;
  background-color: ${({ expanded }) => (expanded ? "#1f1f1f" : "transparent")};
  border-radius: 4px;
  width: 100%;
`;

const FooterItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
`;

const DropdownIcon = styled.span`
  cursor: pointer;
  margin-left: 10px;
`;

const DropdownMenu = styled.div`
  width: 100%;
  background-color: #1f1f1f;
  //   border-top: 1px solid #c89c5c;
  padding: 10px 0;
`;

const DropdownItem = styled.div`
  padding: 5px 8px;
  cursor: pointer;

  &:hover {
    background-color: #c89c5c;
    color: #1f1f1f;
  }
`;

export default Investments;

import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import { QRCodeSVG } from "qrcode.react";
import LoaderComponent from "../Loader/Loader";
import toast from "react-hot-toast";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../context/firebase";
import { UserContext } from "../../context/UserContext";

export default function DepositModal({ close }) {
  const [success, setSuccess] = useState(false);
  const [stage, setStage] = useState(0);
  // const { userBalance, updateUserBalance } = useContext(UserContext);
  const [coupon, setCoupon] = useState("");
  const [depositValue, setDepositValue] = useState(null);
  const [qrCodeValue, setQrCodeValue] = useState(
    "00020126360014br.gov.bcb.pix0114373197090001645204000053039865802BR5922OOBYOU TECNOLOGIA LTDA6014RIO DE JANEIRO62070503***63047315"
  );
  const [file, setFile] = useState(null);
  const calculateChecksum = (data) => {
    let crc = 0xffff; // Valor inicial
    const polynomial = 0x1021; // Polinômio CRC-16-CCITT-FALSE

    for (let i = 0; i < data.length; i++) {
      crc ^= data.charCodeAt(i) << 8;
      for (let j = 0; j < 8; j++) {
        if ((crc & 0x8000) !== 0) {
          crc = (crc << 1) ^ polynomial;
        } else {
          crc = crc << 1;
        }
        crc &= 0xffff; // Garante 16 bits
      }
    }

    return crc.toString(16).toUpperCase().padStart(4, "0");
  };
  const generatePixCode = (transactionValue) => {
    if (!transactionValue || transactionValue <= 0) {
      throw new Error("Valor de transação inválido. Deve ser maior que 0.");
    }
    const formattedValue = transactionValue.toFixed(2).replace(".", ".");
    const amountLength = formattedValue.length;
    const lengthIndicator = `54${amountLength.toString().padStart(2, "0")}`;

    const basePix =
      `00020126360014br.gov.bcb.pix011437319709000164520400005303986${lengthIndicator}${formattedValue}5802BR5922OOBYOU TECNOLOGIA LTDA6014RIO DE JANEIRO62070503***6304`.trim();
    const checksum = calculateChecksum(basePix);
    return `${basePix}${checksum}`;
  };

  const handleNext = async () => {
    if (!depositValue || depositValue <= 0) {
      toast.error("Por favor, insira um valor de depósito válido.");
      return;
    }

    try {
      const pixCode = generatePixCode(depositValue);
      setQrCodeValue(pixCode);
      setStage("loading");

      // Make deposit API call here
      const token = localStorage.getItem("userToken");
      if (!token) {
        toast.error("Você precisa estar logado para fazer um depósito");
        return;
      }

      const response = await fetch("https://userdeposit-qj6yfdqbiq-uc.a.run.app", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: depositValue,
          type: "PIX",
        }),
      });

      if (!response.ok) {
        toast.error(`Erro ao fazer depósito, entre em contato com o suporte`);
        setStage(2);
        throw new Error(`Erro HTTP! status: ${response.status}`);
      }

      const data = await response.json();
      // Store transaction data for later use with receipt
      localStorage.setItem("currentDepositId", data.transactionId);
      localStorage.setItem("currentUserId", data.userId);

      setTimeout(() => {
        setStage(1);
      }, 1000);
    } catch (error) {
      console.error("Erro ao gerar código Pix ou fazer depósito:", error);
      toast.error("Falha ao processar o depósito.");
      setStage(2);
    }
  };

  const handleConfirm = async () => {
    if (!file) {
      toast.error("Por favor, selecione um comprovante");
      return;
    }

    setStage("loading");
    try {
      const depositId = localStorage.getItem("currentDepositId");
      const userId = localStorage.getItem("currentUserId");

      // Upload file first
      const storageRef = ref(storage, `users/${userId}/receipts/${depositId}.pdf`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      // Then send receipt URL to API
      const token = localStorage.getItem("userToken");
      if (!token) {
        toast.error("Você precisa estar logado para adicionar um recibo de depósito");
        return;
      }

      const response = await fetch("https://useradddepositreceipt-qj6yfdqbiq-uc.a.run.app", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          depositId: depositId,
          receiptUrl: downloadURL,
        }),
      });

      if (!response.ok) {
        toast.error(`Erro ao adicionar recibo de depósito, entre em contato com o suporte`);
        throw new Error(`Erro HTTP! status: ${response.status}`);
      }

      setSuccess(true);
      setStage(2);

      // Clean up localStorage
      localStorage.removeItem("currentDepositId");
      localStorage.removeItem("currentUserId");
    } catch (error) {
      console.error("Erro ao processar comprovante:", error);
      setStage(2);
    }
  };

  const inputRef = useRef(null);

  useEffect(() => {
    const inputElement = inputRef.current;

    const handleInput = (e) => {
      const currentValue = e.target.value.replace(/\D/g, ""); // Remove caracteres não numéricos
      const numericValue = parseInt(currentValue, 10);

      if (numericValue > 10000) {
        inputElement.value = depositValue ? `R$ ${depositValue}` : "";
      } else {
        setDepositValue(numericValue || null);
        inputElement.value = numericValue ? `R$ ${numericValue}` : "";
      }
    };

    inputElement.addEventListener("input", handleInput);

    return () => {
      inputElement.removeEventListener("input", handleInput);
    };
  }, [depositValue]);

  return (
    <>
      <Overlay onClick={() => close(true)} />
      <Popup>
        <div
          style={{
            position: "absolute",
            top: "25px",
            right: "30px",
            zIndex: "1000",
            cursor: "pointer",
            fontWeight: "bold",
          }}
          onClick={() => close(true)}
        >
          X
        </div>
        {stage === "loading" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <LoaderComponent />
          </div>
        )}
        {stage === 0 && (
          <>
            <Header style={{ textAlign: "center" }}>DEPÓSITO</Header>
            <div
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#c09c5c",
                marginBottom: "20px",
              }}
            ></div>

            <SubHeader style={{ textAlign: "center" }}>
              Valor do depósito
            </SubHeader>
            <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
              <ValueButton onClick={() => setDepositValue(depositValue + 250)}>
                +R$ 250
              </ValueButton>
              <ValueButton onClick={() => setDepositValue(depositValue + 1000)}>
                +R$ 1000
              </ValueButton>
              <ValueButton onClick={() => setDepositValue(depositValue + 5000)}>
                +R$ 5000
              </ValueButton>
            </div>

            <Input
              ref={inputRef}
              placeholder="Digite o valor"
              value={depositValue ? `R$ ${depositValue}` : ""}
              type="text"
              inputMode="numeric"
              max="900000"
              pattern="[0-9]+"
            />

            <Button
              onClick={handleNext}
              style={{ backgroundColor: "#00C160" }}
            >
              PRÓXIMO
            </Button>
          </>
        )}

        {stage === 1 && (
          <>
            <Header style={{ textAlign: "center" }}>FAÇA O DEPÓSITO</Header>
            <div
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#c09c5c",
                marginBottom: "20px",
              }}
            ></div>
            <SubHeader style={{ textAlign: "center" }}>
              Leia o QR Code no app do seu banco
            </SubHeader>
            <div
              style={{
                padding: "10px",
                background: "white",
                width: "212px",
                margin: "auto",
              }}
            >
              <QRCodeSVG value={qrCodeValue} size={192} />
            </div>
            <SubHeader style={{ textAlign: "center" }}></SubHeader>

            <ButtonCopy
              onClick={() => {
                navigator.clipboard.writeText(qrCodeValue);
                toast.success("QR Code copiado com sucesso!");
              }}
            >
              COPIAR QR CODE
            </ButtonCopy>

            <div
              style={{ width: "100%", textAlign: "center", marginTop: "1rem" }}
            >
              <SubHeader style={{ fontSize: "1rem", marginBottom: "10px" }}>
                Anexe seu comprovante de R${" "}
                {depositValue?.toFixed(2).replace(".", ",")}
              </SubHeader>

              <label
                htmlFor="receipt"
                style={{ cursor: "pointer", width: "100%" }}
              >
                <div
                  style={{
                    backgroundColor: "#00C1FF",
                    color: "white",
                    padding: "10px",
                    borderRadius: "5px",
                    display: "inline-block",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  {file ? file.name : "SELECIONAR ARQUIVO"}
                </div>
                <input
                  type="file"
                  id="receipt"
                  accept=".pdf,image/*"
                  style={{ display: "none" }}
                  value={file ? file.filename : ""}
                  onChange={(e) => {
                    const selectedFile = e.target.files[0];
                    if (selectedFile) {
                      setFile(selectedFile);
                    }
                  }}
                />
              </label>
            </div>

            <div style={{ marginBottom: "1rem" }}>
              <Button
                onClick={handleConfirm}
                style={{ backgroundColor: "#00C160", width: "100%" }}
              >
                CONFIRMAR
              </Button>
              <TermsText style={{ marginTop: "0.5rem" }}>
                <TermsLink>Termos e Condições </TermsLink>aplicaveis a este
                depósito.
              </TermsText>
            </div>
          </>
        )}
        {stage === 2 && (
          <>
            <Header
              style={{
                marginTop: "18rem",
                textAlign: "center",
              }}
            >
              {success ? (
                <>
                  TRANSAÇÃO ADICIONADA <span>COM SUCESSO</span>
                </>
              ) : (
                <>
                  <span style={{ color: "red" }}>ERRO</span> AO ADICIONAR
                  TRANSAÇÃO
                </>
              )}
            </Header>
            <Button onClick={close}>OK</Button>
          </>
        )}
      </Popup>
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 900;
  cursor: pointer;
`;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 700px;
  width: 450px;
  background-color: #121212;
  z-index: 1000;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;

  @media (max-width: 500px) {
    height: 600px;
    width: 90%;
  }
`;

const Header = styled.h2`
  font-size: 2rem;
  color: #ffffff;

  span {
    color: #c09c5c;
  }
`;

const SubHeader = styled.h4`
  font-size: 1.5rem;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 10px;

  @media (max-width: 340px) {
    font-size: 1rem;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  background-color: #1c1c1c;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1rem;

  /* Estiliza o input number para remover as setas */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield; /* Para Firefox */
  }
`;

const ButtonCopy = styled.button`
  width: 50%;
  margin-inline: auto;
  padding: 10px;
  background-color: transparent;
  color: white;
  font-weight: bold;
  border: 1px solid #c09c5c;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: -2rem;

  @media (max-width: 545px) {
    margin-top: -1.2rem;
  }

  &:hover {
    background-color: #c09c5c;
  }
`;

const Button = styled.button`
  padding: 10px;
  background-color: #c09c5c;
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: auto;

  &:hover {
    background-color: #8c7444;
  }
`;

const ValueButton = styled.button`
  background-color: #333333;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 30%;
  margin-inline: auto;

  &:hover {
    background-color: #444444;
  }
`;

const TermsText = styled.p`
  font-size: 0.9rem;
  color: #fff;
  margin-top: 0px;
  text-align: center;
  margin-bottom: -20px;
`;

const TermsLink = styled.span`
  color: #2c65ff;
  cursor: pointer;
`;

import React, { useState, useRef } from "react";
import Spacing from "../../Spacing";
import SectionHeadingStyle3 from "../../SectionHeading/SectionHeadingStyle3";
import { pageTitle } from "../../../helpers/PageTitle";
import { Input } from "../Profile";
import styled from "styled-components";
import toast from "react-hot-toast";
import LoaderComponent from "../../Loader/Loader";
import { useNumericInput } from "../../../hooks/useNumericInput";
import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../../context/firebase";

const StyledInput = styled(Input)`
  &:focus {
    background-color: #1a1a1a !important;
    border-color: #d4af37 !important;
    box-shadow: 0 0 0 0.25rem rgba(212, 175, 55, 0.25) !important;
    color: #fff !important;
  }

  &::placeholder {
    color: #888 !important;
    opacity: 1;
  }
`;

const StyledTextarea = styled.textarea`
  background-color: #0a0a0a;
  border: 1px solid #c8af86;

  &:focus {
    background-color: #1a1a1a !important;
    border-color: #d4af37 !important;
    box-shadow: 0 0 0 0.25rem rgba(212, 175, 55, 0.25) !important;
    color: #fff !important;
  }

  &::placeholder {
    color: #888 !important;
    opacity: 1;
  }
`;

export default function CreateProject() {
  pageTitle("Create Project");
  const sharePriceRef = useNumericInput();
  const stepperRef = useRef(null);
  const totalSharesRef = useRef(null);
  const reservedSharesRef = useRef(null);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    sharePrice: "",
    totalShares: "",
    reservedShares: "",
    startDate: "",
    endDate: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [selectedFiles, setSelectedFiles] = useState({
    image: null,
    thumbnail: null,
    dueDiligence: null,
    prospectus: null,
    presentation: null,
    fullDescription: null,
  });

  const [projectId, setProjectId] = useState(null);

  const fileTypes = {
    imageUrl: "image",
    thumbnailUrl: "thumbnail",
    dueDiligenceUrl: "dueDiligence",
    prospectusUrl: "prospectus",
    presentationUrl: "presentation",
    fullDescriptionUrl: "fullDescription",
  };

  const handleFileUpload = async (file, fileType) => {
    try {
      if (!file) return null;

      const contentTypeMap = {
        md: "text/markdown",
        pdf: "application/pdf",
        doc: "application/msword",
        docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        image: "image/*",
      };

      const extension = file.name.split(".").pop().toLowerCase();
      const contentType = contentTypeMap[extension] || file.type;

      const storageRef = ref(storage, `projects/${projectId}/${fileType}`);

      const metadata = {
        contentType: contentType,
      };

      await uploadBytes(storageRef, file, metadata);
      const downloadURL = await getDownloadURL(storageRef);

      return downloadURL;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (files && files[0]) {
      const fileType = name.replace("Url", "");
      setSelectedFiles((prev) => {
        const newState = {
          ...prev,
          [fileType]: files[0],
        };
        return newState;
      });
    } else {
      if (name === "sharePrice") {
        // Permite números e ponto decimal
        const numericValue = value.replace(/[^\d.]/g, "");
        if (numericValue.split(".").length > 2) return;

        setFormData((prev) => ({
          ...prev,
          [name]: numericValue,
        }));
      } else if (name === "totalShares" || name === "reservedShares") {
        // Permite apenas números inteiros
        const integerValue = value.replace(/\D/g, "");

        setFormData((prev) => ({
          ...prev,
          [name]: integerValue,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    }
  };

  const handleFirstStep = async () => {
    setLoading(true);
    setError("");

    try {
      const token = localStorage.getItem("userToken");

      if (!token) {
        toast.error("Invalid session");
        throw new Error("Invalid session");
      }

      // Enviar apenas dados básicos
      const basicFormData = {
        title: formData.title.trim(),
        description: formData.description.trim(),
        sharePrice: parseFloat(formData.sharePrice),
        totalShares: parseInt(formData.totalShares),
        reservedShares: parseInt(formData.reservedShares) || 0,
        startDate: formData.startDate,
        endDate: formData.endDate,
      };

      const response = await fetch(
        "https://addcrowdfundproject-qj6yfdqbiq-uc.a.run.app",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(basicFormData),
        }
      );

      if (!response.ok) {
        throw new Error("Error creating project");
      }

      const data = await response.json();
      setProjectId(data.projectId);
      toast.success("Basic information saved successfully!");
      setFormData({
        title: "",
        description: "",
        sharePrice: "",
        totalShares: "",
        reservedShares: "",
        startDate: "",
        endDate: "",
      });
      stepperRef.current.nextCallback();
    } catch (err) {
      setError(err.message);
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSecondStep = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const token = localStorage.getItem("userToken");

      if (!token) {
        console.error("Token não encontrado, sessão inválida");
        toast.error("Invalid session");
        throw new Error("Invalid session");
      }

      // Upload de arquivos e coleta de URLs
      const uploadedUrls = {
        imageUrl: "",
        thumbnailUrl: "",
        dueDiligenceUrl: "",
        prospectusUrl: "",
        presentationUrl: "",
        fullDescriptionUrl: "",
      };

      for (const [fieldName, fileType] of Object.entries(fileTypes)) {
        if (selectedFiles[fileType]) {
          const url = await handleFileUpload(selectedFiles[fileType], fileType);
          if (url) {
            uploadedUrls[fieldName] = url;
          } else {
            console.warn(`URL para ${fileType} não foi obtida`);
          }
        } else {
          console.log(`Nenhum arquivo selecionado para ${fileType}`);
        }
      }

      // Adicionar videoUrl aos dados
      uploadedUrls.videoUrl = formData.videoUrl;
      const response = await fetch(
        `https://updatecrowdfundproject-qj6yfdqbiq-uc.a.run.app`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ ...uploadedUrls, projectId: projectId }),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Erro ao atualizar projeto:", errorText);
        throw new Error("Error updating project");
      }

      setSuccess("Files added successfully!");

      // Resetar formulário
      setSelectedFiles({});
      setProjectId(null);
    } catch (err) {
      console.error("Erro no catch:", err.message);
      setError(err.message);
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const calculateGoalAmount = () => {
    if (formData.sharePrice && formData.totalShares) {
      return Number(formData.sharePrice) * Number(formData.totalShares);
    }
    return 0;
  };

  return (
    <>
      {loading && <LoaderComponent fullPage={true} />}
      <Spacing lg="90" md="80" />
      <section>
        <div className="container">
          <SectionHeadingStyle3
            title="Create Project"
            subTitle="New Project"
            shape="shape_5"
          />
          <div className="row">
            <div className="col-md-12">
              <div
                style={{ background: "#222221" }}
                className="p-4 text-light rounded"
              >
                {error && <div className="alert alert-danger">{error}</div>}
                {success && (
                  <div className="alert alert-success">{success}</div>
                )}

                <form>
                  <Stepper ref={stepperRef} orientation="vertical">
                    <StepperPanel
                      header="Basic Information"
                      pt={{
                        header: {
                          className: "d-none",
                        },
                      }}
                    >
                      <div className="flex flex-column">
                        <div className="mb-3">
                          <label className="form-label">Project Title</label>
                          <StyledInput
                            placeholder="My Project"
                            type="text"
                            name="title"
                            className="form-control"
                            value={formData.title}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="mb-3">
                          <label className="form-label">
                            Short description
                          </label>
                          <StyledTextarea
                            placeholder="My Short Description"
                            name="description"
                            className="form-control"
                            rows="4"
                            value={formData.description}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="mb-3">
                          <label className="form-label">Share Price (R$)</label>
                          <StyledInput
                            type="text"
                            inputMode="numeric"
                            value={formData.sharePrice}
                            name="sharePrice"
                            className="form-control"
                            onChange={handleChange}
                            placeholder="0.00"
                          />
                        </div>

                        <div className="mb-3">
                          <label className="form-label">Total Shares</label>
                          <StyledInput
                            type="text"
                            inputMode="numeric"
                            value={formData.totalShares}
                            name="totalShares"
                            className="form-control"
                            onChange={handleChange}
                            ref={totalSharesRef}
                          />
                        </div>

                        <div className="mb-3">
                          <label className="form-label">Reserved Shares</label>
                          <StyledInput
                            type="text"
                            inputMode="numeric"
                            value={formData.reservedShares}
                            name="reservedShares"
                            className="form-control"
                            onChange={handleChange}
                            ref={reservedSharesRef}
                          />
                        </div>

                        <div className="mb-3">
                          <label className="form-label">Goal Amount (R$)</label>
                          <StyledInput
                            style={{ background: "#1a1a1a" }}
                            type="text"
                            value={calculateGoalAmount()}
                            className="form-control"
                            disabled
                          />
                        </div>

                        <div className="mb-3">
                          <label className="form-label">Start Date</label>
                          <StyledInput
                            type="date"
                            name="startDate"
                            className="form-control"
                            value={formData.startDate}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="mb-3">
                          <label className="form-label">End Date</label>
                          <StyledInput
                            type="date"
                            name="endDate"
                            className="form-control"
                            value={formData.endDate}
                            onChange={handleChange}
                          />
                        </div>

                        <button
                          disabled={loading}
                          type="button"
                          className="cs_btn cs_style_1"
                          onClick={() => {
                            handleFirstStep();
                          }}
                        >
                          {loading ? "Saving..." : "Save and Continue"}
                        </button>
                      </div>
                    </StepperPanel>

                    <StepperPanel
                      header="Basic Information"
                      pt={{
                        header: {
                          className: "d-none",
                        },
                      }}
                    >
                      <div className="flex flex-column">
                        <div className="mb-3">
                          <label className="form-label">
                            Project Video URL (YouTube)
                          </label>
                          <StyledInput
                            type="text"
                            name="videoUrl"
                            className="form-control"
                            value={formData.videoUrl}
                            onChange={handleChange}
                            placeholder="https://youtube.com/..."
                          />
                        </div>

                        <div className="mb-3">
                          <label className="form-label">Project Image</label>
                          <StyledInput
                            style={{ color: "#888", paddingLeft: "1.5rem" }}
                            type="file"
                            name="imageUrl"
                            className="form-control"
                            onChange={handleChange}
                            accept="image/*"
                          />
                        </div>

                        <div className="mb-3">
                          <label className="form-label">Thumbnail Image</label>
                          <StyledInput
                            style={{ color: "#888", paddingLeft: "1.5rem" }}
                            type="file"
                            name="thumbnailUrl"
                            className="form-control"
                            onChange={handleChange}
                            accept="image/*"
                          />
                        </div>

                        <div className="mb-3">
                          <label className="form-label">Full description</label>
                          <StyledInput
                            style={{ color: "#888", paddingLeft: "1.5rem" }}
                            type="file"
                            name="fullDescriptionUrl"
                            className="form-control"
                            onChange={handleChange}
                            accept=".md"
                          />
                        </div>

                        <div className="mb-3">
                          <label className="form-label">
                            Due Diligence Document
                          </label>
                          <StyledInput
                            style={{ color: "#888", paddingLeft: "1.5rem" }}
                            type="file"
                            name="dueDiligenceUrl"
                            className="form-control"
                            onChange={handleChange}
                            accept=".pdf,.doc,.docx"
                          />
                        </div>

                        <div className="mb-3">
                          <label className="form-label">
                            Prospectus Document
                          </label>
                          <StyledInput
                            style={{ color: "#888", paddingLeft: "1.5rem" }}
                            type="file"
                            name="prospectusUrl"
                            className="form-control"
                            onChange={handleChange}
                            accept=".pdf,.doc,.docx"
                          />
                        </div>

                        <div className="mb-3">
                          <label className="form-label">
                            Presentation Document
                          </label>
                          <StyledInput
                            style={{ color: "#888", paddingLeft: "1.5rem" }}
                            type="file"
                            name="presentationUrl"
                            className="form-control"
                            onChange={handleChange}
                            accept=".pdf,.doc,.docx"
                          />
                        </div>

                        <div style={{ display: "flex", gap: "1rem" }}>
                          <button
                            disabled={loading}
                            type="button"
                            className="cs_btn cs_style_1"
                            onClick={() => stepperRef.current.prevCallback()}
                          >
                            Back
                          </button>
                          <button
                            disabled={loading}
                            type="button"
                            className="cs_btn cs_style_1"
                            onClick={handleSecondStep}
                          >
                            {loading ? "Finishing..." : "Finish Project"}
                          </button>
                        </div>
                      </div>
                    </StepperPanel>
                  </Stepper>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

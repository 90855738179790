import React, { useState, useEffect, useCallback, useContext } from "react";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import { pageTitle } from "../../helpers/PageTitle";
import { useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import LoaderComponent from "../Loader/Loader";
import toast from "react-hot-toast";
import styled from "styled-components";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../context/firebase";
import { getCroppedImg } from "./cropUtils";
import Cropper from "react-easy-crop";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

export default function Profile() {
  const { updateHeader, setUpdateHeader, setProfilePicture } =
    useContext(UserContext);
  const location = useLocation();
  const [consultantParams, setConsultantParams] = useState({
    consultantName: "",
    consultantId: "",
  });
  const [profileData, setProfileData] = useState({
    uid: "",
    accessLevel: 0,
    avatar: "none",
    name: "",
    email: "",
    cpf: "",
    birthdate: "",
    gender: "",
    nationality: "",
    address: "",
    phone: "",
    consultant: "",
    consultantId: "",
    income: 0,
    balance: 0,
    verified: false,
    investor: false,
    cvmApproved: false,
  });
  const [originalData, setOriginalData] = useState({});
  const [isEditable, setIsEditable] = useState(
    location.state?.isEditable || false
  );
  const [loading, setLoading] = useState(true);
  const [newAvatar, setNewAvatar] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [cropComplete, setCropComplete] = useState(false);
  const [tempCroppedBlob, setTempCroppedBlob] = useState(null);
  const navigate = useNavigate();
  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname.includes("/profile/")) {
      const paramsString = pathname.split("/profile/")[1];
      const paramPairs = paramsString.split("&");
      const consultantParams = {};
      paramPairs.forEach((pair) => {
        const [key, value] = pair.split("=");
        consultantParams[key] = value;
      });
      setConsultantParams({
        consultantName: consultantParams.consultantName || "",
        consultantId: consultantParams.consultantId || "",
      });
    }
  }, []);

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageDataUrl = URL.createObjectURL(file);
      setNewAvatar(imageDataUrl);
    }
  };

  const handleCropAndUpload = async () => {
    if (!newAvatar || !croppedAreaPixels) return;
    setCropComplete(true);

    try {
      const croppedAndResizedBlob = await getCroppedImg(
        newAvatar,
        croppedAreaPixels,
        200,
        200
      );
      setTempCroppedBlob(croppedAndResizedBlob);
      const tempURL = URL.createObjectURL(croppedAndResizedBlob);
      setProfileData((prevData) => ({ ...prevData, avatar: tempURL }));
      setProfilePicture(tempURL);
      toast.success("Clique em 'Salvar Alterações' para confirmar.");
    } catch (error) {
      console.error("Erro ao cortar imagem:", error);
      toast.error("Erro ao processar imagem");
    }
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      const token = localStorage.getItem("userToken");

      if (!token) {
        toast.error("Sessão inválida, por favor faça login novamente.");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          "https://checkuserandgetinfo-qj6yfdqbiq-uc.a.run.app",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          if (response.status === 401) {
            toast.error("Sessão expirada, por favor faça login novamente.");
            return;
          }
          throw new Error("Erro ao buscar dados do perfil");
        }

        const data = await response.json();
        const filteredData = Object.fromEntries(
          Object.entries(data).map(([key, value]) => [
            key,
            value === "none" || value === "default" ? "" : value,
          ])
        );

        const updatedData = {
          ...filteredData,
          consultantId:
            filteredData.consultantId || consultantParams.consultantId,
          consultant:
            filteredData.consultant || consultantParams.consultantName,
        };

        console.log("Dados atualizados com consultor:", updatedData);
        setProfileData(updatedData);
        setOriginalData(data);
      } catch (error) {
        console.error("Erro ao buscar dados do perfil", error);
        toast.error("Erro ao carregar dados do perfil");
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, [consultantParams.consultantId]);

  const isValidCPF = (cpf) => {
    cpf = cpf.replace(/\D/g, "");

    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(cpf.charAt(i)) * (10 - i);
    }

    let firstVerifier = (sum * 10) % 11;
    if (firstVerifier === 10) firstVerifier = 0;
    if (firstVerifier !== parseInt(cpf.charAt(9))) return false;

    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(cpf.charAt(i)) * (11 - i);
    }

    let secondVerifier = (sum * 10) % 11;
    if (secondVerifier === 10) secondVerifier = 0;
    return secondVerifier === parseInt(cpf.charAt(10));
  };

  const formatCPF = (value) => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})$/, "$1-$2")
      .substring(0, 14);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevData) => ({
      ...prevData,
      [name]: name === "cpf" ? formatCPF(value) : value,
    }));
  };

  const handleSaveProfile = async (e) => {
    e.preventDefault();
    setLoading(true);

    const token = localStorage.getItem("userToken");
    if (!token) {
      toast.error("Sessão expirada, por favor faça login novamente.");
      setLoading(false);
      return;
    }

    let downloadURL = profileData.avatar;

    if (tempCroppedBlob) {
      try {
        const storageRef = ref(storage, `avatars/${profileData.uid}`);
        await uploadBytes(storageRef, tempCroppedBlob);
        downloadURL = await getDownloadURL(storageRef);
      } catch (error) {
        console.error("Erro ao fazer upload do avatar:", error);
        toast.error("Erro ao fazer upload do avatar");
        setLoading(false);
        return;
      }
    }

    const updatedData = {
      ...profileData,
      avatar: downloadURL,
      consultant: profileData.consultant || consultantParams.consultantName,
      consultantId: profileData.consultantId || consultantParams.consultantId,
    };

    try {
      const response = await fetch(
        "https://updateuserinfo-qj6yfdqbiq-uc.a.run.app",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updatedData),
        }
      );

      if (!response.ok) {
        throw new Error("Erro ao salvar dados do perfil");
      } else {
        setUpdateHeader(updateHeader + 1);
        setProfilePicture(downloadURL);
        navigate(
          `/profile/consultantId=${consultantParams.consultantId}&consultantName=${consultantParams.consultantName}`
        );
        setIsEditable(false);
        setOriginalData(updatedData);
        setTempCroppedBlob(null);
        toast.success("Perfil atualizado com sucesso!");
      }
    } catch (error) {
      console.error("Erro ao atualizar perfil", error);
      toast.error("Erro ao atualizar perfil");
    } finally {
      setLoading(false);
    }
  };

  const toggleEdit = () => {
    setIsEditable((prev) => !prev);
  };

  pageTitle("Perfil");

  return (
    <>
      <Spacing lg="100" md="80" />
      <section>
        <div className="container">
          <SectionHeadingStyle3
            title="Meu Perfil"
            subTitle="Perfil"
            shape="shape_6"
          />
          {loading ? (
            <LoaderComponent opacity={1} fullPage={true} />
          ) : (
            <FormContainer>
              <form onSubmit={handleSaveProfile}>
                {newAvatar && !cropComplete ? (
                  <CropContainer
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      position: "relative",
                      width: "100%",
                      maxWidth: "200px",
                    }}
                  >
                    <Cropper
                      image={newAvatar}
                      crop={crop}
                      zoom={zoom}
                      aspect={1}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={onCropComplete}
                      style={{
                        containerStyle: { width: "300px", height: "300px" },
                      }}
                    />
                    <ButtonImage onClick={handleCropAndUpload}>
                      Confirmar
                    </ButtonImage>
                  </CropContainer>
                ) : (
                  <AvatarContainer>
                    <AvatarImage
                      src={
                        profileData.avatar && profileData.avatar !== "none"
                          ? profileData.avatar
                          : process.env.PUBLIC_URL + "/perfil.png"
                      }
                      alt="Avatar"
                      onError={(e) => {
                        e.target.onerror = null;
                        console.log("Erro ao carregar imagem:", e);
                        e.target.src = process.env.PUBLIC_URL + "/perfil.png";
                      }}
                    />
                    {isEditable && (
                      <Button as="label" htmlFor="avatarUpload">
                        <Icon icon="bi:camera" style={{ marginRight: "8px" }} />
                        Alterar Avatar
                      </Button>
                    )}
                    <input
                      id="avatarUpload"
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleAvatarChange}
                    />
                  </AvatarContainer>
                )}

                <label htmlFor="name">Nome</label>
                <Input
                  id="name"
                  type="text"
                  name="name"
                  placeholder="Nome"
                  value={profileData.name}
                  onChange={handleChange}
                  disabled={!isEditable}
                />

                <label htmlFor="cpf">CPF</label>
                <Input
                  id="cpf"
                  type="text"
                  name="cpf"
                  placeholder="CPF"
                  value={profileData.cpf}
                  onChange={handleChange}
                  disabled={!isEditable}
                />

                <label htmlFor="birthdate">Data de Nascimento</label>
                <Input
                  id="birthdate"
                  type="date"
                  name="birthdate"
                  placeholder="Data de Nascimento"
                  value={profileData.birthdate}
                  onChange={handleChange}
                  disabled={!isEditable}
                />

                <label htmlFor="gender">Gênero</label>
                <select
                  id="gender"
                  name="gender"
                  value={profileData.gender}
                  onChange={handleChange}
                  disabled={!isEditable}
                  style={{
                    width: "100%",
                    padding: "12px",
                    marginBottom: "15px",
                    backgroundColor: "#0a0a0a",
                    border: "1px solid #c8af86",
                    borderRadius: "4px",
                    fontSize: "16px",
                    marginTop: "5px",
                    color: "white",
                  }}
                >
                  {profileData.gender !== "" && !isEditable && (
                    <option value="">{profileData.gender}</option>
                  )}
                  {isEditable && (
                    <>
                      <option value="">Selecione o gênero</option>
                      <option value="Male">Masculino</option>
                      <option value="Female">Feminino</option>
                      <option value="Other">Outro</option>
                    </>
                  )}
                </select>

                <label htmlFor="nationality">Nacionalidade</label>
                <Input
                  id="nationality"
                  type="text"
                  name="nationality"
                  placeholder="Nacionalidade"
                  value={profileData.nationality}
                  onChange={handleChange}
                  disabled={!isEditable}
                />

                <label htmlFor="address">Endereço</label>
                <Input
                  id="address"
                  type="text"
                  name="address"
                  placeholder="Endereço"
                  value={profileData.address}
                  onChange={handleChange}
                  disabled={!isEditable}
                />

                <label htmlFor="phone">Telefone</label>
                <Input
                  id="phone"
                  type="tel"
                  name="phone"
                  placeholder="Telefone"
                  value={profileData.phone}
                  onChange={handleChange}
                  disabled={!isEditable}
                />

                <label htmlFor="income">Renda Anual</label>
                <Input
                  id="income"
                  type="text"
                  name="income"
                  placeholder="Renda Anual"
                  value={profileData.income}
                  onChange={handleChange}
                  disabled={!isEditable}
                />

                <label htmlFor="consultant">Consultor</label>
                <Input
                  id="consultant"
                  type="text"
                  name="consultant"
                  value={
                    profileData.consultant || consultantParams.consultantName
                  }
                  disabled={true}
                />

                <Button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    if (isEditable) {
                      handleSaveProfile(e);
                    } else {
                      toggleEdit();
                    }
                  }}
                  style={{ marginTop: "15px" }}
                >
                  {isEditable ? "Salvar Alterações" : "Editar"}
                </Button>
              </form>
            </FormContainer>
          )}
        </div>
      </section>
    </>
  );
}

const FormContainer = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 40px;
  background-color: #171717;
  border-radius: 8px;
  border: 3px solid #c8af86;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 150px;
  gap: 20px;
  color: #fff;
`;

const CropContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 400px;
  margin: auto;
  margin-left: 105px;

  @media (max-width: 770px) {
    margin-left: 70px;
  }

  @media (max-width: 575px) {
    margin-left: 80px;
  }

  @media (max-width: 550px) {
    margin-left: 70px;
  }

  @media (max-width: 520px) {
    margin-left: 60px;
  }

  @media (max-width: 500px) {
    margin-left: 50px;
  }

  @media (max-width: 475px) {
    margin-left: 40px;
  }

  @media (max-width: 450px) {
    margin-left: 30px;
  }

  @media (max-width: 425px) {
    margin-left: 10px;
  }

  @media (max-width: 400px) {
    margin-left: -5px;
  }

  @media (max-width: 375px) {
    margin-left: -15px;
  }
`;

const ButtonImage = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #866d40;
  color: white;
  border: none;
  border-radius: 4px;
  zindex: 999;
  margin-top: 300px;
  margin-left: 100px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  &:not(:disabled):hover {
    background-color: #c8af86;
  }
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  text-align: center;
  justify-content: center;
`;

const AvatarImage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
  background-color: #ddd;
  border: 2px solid #ddd;
`;

export const Input = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  background-color: #0a0a0a;
  border: 1px solid #c8af86;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 5px;
`;

const Button = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #866d40;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  &:not(:disabled):hover {
    background-color: #c8af86;
  }
`;
